/* eslint-disable no-undef */
/* eslint-disable no-unreachable */
import moment, { ISO_8601 } from "moment";
import models from "../../repository/models";
import AppStorageError from "../../errorDefinition/AppStorageError";
import BusinessError from "../../errorDefinition/BusinessError";
import ClientError from "../../errorDefinition/ClientError";
import ApiErrorResponse from "../../errorDefinition/ApiErrorResponse";
import ConnectionErrorNotFound from "../../errorDefinition/ConnectionErrorNotFound";
import ConnectionErrorTimeOut from "../../errorDefinition/ConnectionErrorTimeOut";
import ConnectionErrorServerSide from "../../errorDefinition/ConnectionErrorServerSide";
import ConnectionErrorFetchFaild from "../../errorDefinition/ConnectionErrorFetchFaild";
import { checkEmployeeToken } from "../authentication";
import RotaService from "../route/index";
import { fetchPollyfill } from "../AbstractService";
import { getUser } from "../authentication/index";
import Moeda from "../../utils/Moeda";
import * as R from "ramda";

const rotaService = new RotaService();
/**
 * @description Responsavel por receber diversos dados de clientes ( normalmente providos pela api do servidor)
 * e armazena-los através dos repositórios
 * @param {Object} client Dados de clientes
 * @throws {ClientError} Lança um erro predefinido que mostrará um popup na tela e registrará o
 * evento no armazenamento local.
 */
export async function addClienteAPI(client) {
  try {
    const shouldUseSecurityLog = false;

    client["appSyncDate"] = new Date();
    client["appEditedDate"] = new Date();
    client["assinatura"] = client.assinatura || "";
    client.movimentacoes = client.movimentacoes || [];
    client.agendamentos = client.agendamentos || [];
    client.shouldSync = false;
    client.bairroIdCache = client.bairro.id || undefined;
    client.hash = client.hash || window.DATABASE.generateUniqId();
    client.diariaOrder = 999999;
    client.lowerCaseNome = client.nomeLatinizado.toLowerCase();
    client.hash = String(client.hash);
    client.cidade = client.bairro && client.bairro.cidade;
    client.isFotoDownloaded = false;
    const agendamentosARG = client.agendamentos;
    const bairroARG = client.bairro;
    const telefonesARG = client.telefones;
    const canceladasARG = client.canceladas;
    const trocasAvulsoArgs = client.trocasAvulso;
    const documentosARG = client.documentos;
    const movimentacoesARG = client.movimentacoes;
    delete client.movimentacoes;
    delete client.agendamentos;
    delete client.telefones;
    delete client.canceladas;
    delete client.bairro;
    delete client.trocasAvulso;
    delete client.documentos;
    let newId = await models.Cliente.create(client, shouldUseSecurityLog);
    client["DATABASE_ID"] = newId;

    let proms = [];

    if (movimentacoesARG && movimentacoesARG.length) {
      const promsMovimentacao = movimentacoesARG.map((movment) => {
        const tipo = movment.tipoAtividade;
        movment.dataRealizacao = moment(movment.dataRealizacao).toDate();
        movment.hash = String(movment.hash);
        return models[tipo].create(movment, shouldUseSecurityLog);
      });
      proms = proms.concat(promsMovimentacao);
    }

    if (trocasAvulsoArgs && trocasAvulsoArgs.length) {
      let trocasAvulso = trocasAvulsoArgs.map((a) => {
        a.dataRealizacao = moment(a.dataRealizacao).toDate();
        return a;
      });
      let promsTrocaAvulso = models.ClienteTrocaAvulso.bulkCreate(trocasAvulso);
      proms.push(promsTrocaAvulso);
    }

    if (agendamentosARG && agendamentosARG.length) {
      let agendamentos = agendamentosARG.map((a) => {
        a.dataRecebimento = moment(a.dataRecebimento).toDate();
        return a;
      });
      let promsAgendamento = models.ClienteAgendamento.bulkCreate(agendamentos);
      proms.push(promsAgendamento);
    }

    if (bairroARG) {
      let promBairro = models.ClienteBairro.create(
        {
          ClienteId: client.id,
          BairroId: bairroARG.id,
        },
        shouldUseSecurityLog
      );
      proms.push(promBairro);
    }

    if (telefonesARG && telefonesARG.length) {
      let promsTelefones = models.ClienteTelefone.bulkCreate(telefonesARG);
      proms.push(promsTelefones);
    }

    if (documentosARG && documentosARG.length) {
      let promsDocumentos = models.ClienteDocumento.bulkCreate(documentosARG);
      proms.push(promsDocumentos);
    }

    if (canceladasARG && canceladasARG.length) {
      let movimentacoesCanceladas = canceladasARG.map((a) => {
        a.dataRealizacao = moment(a.dataRealizacao).toDate();
        return a;
      });
      let promsCanceladas = models.ClienteMovimentacaoCancelada.bulkCreate(
        movimentacoesCanceladas
      );
      proms.push(promsCanceladas);
    }

    await Promise.all(proms);
  } catch (error) {
    throw new ClientError({
      clientName: client.nome,
      id: client.id,
      hash: client.hash,
      DATABASE_ID: client.DATABASE_ID,
      error: error,
      method: "addClienteAPI",
    });
  }
}

/**
 * @description Responsavel por gerenciar a ordem de inserção de clientes no armazenamento local e download das fotos do cliente
 * @param {Object} clients Array de dados de clientes (normalmente recebidos pela api do servidor)
 */
export async function saveClientsBlock(clients) {
  if (!clients || !clients.length) {
    return;
  }
  clients.map(addClienteAPI);
}

/**
 * @description Função utilizada para armazenar os dados da foto do cliente em memória
 * @param {String} base64Img Foto no formato base64 que será salva junto aos dados de clientes
 * @param {Object} client Dados do cliente
 */
export async function saveClientPicture(client, base64Img) {
  let [clienteUpdate] = await models.Cliente.getAll("id", [client.id]);
  if (!clienteUpdate) return;

  delete clienteUpdate.foto;

  await models.ClienteFoto.savePicture({
    foto: base64Img,
    shouldSync: true,
    ClienteId: clienteUpdate.id,
    ClienteHash: clienteUpdate.hash,
  });

  await models.Cliente.put(clienteUpdate, false);

  return clienteUpdate;
}

/**
 * @description Resposavel por realizar o upload das fotos de clientes
 * @param {Object} clienteInfos dados necessarios para identificar o usuario
 * @param {String} token Token de acesso do usuario, disponivel dentro dos dados de sessão do usuario do app. (Vide service/authentication/index)
 * @param {String} service Url do servidor, tambem disponivel dentro dos dados de sessão de usuario do app
 */
export async function uploadClientePicture(clientInfos, token, service) {
  try {
    if (!clientInfos.fotoUrl) return;

    const response = await fetchPollyfill(
      `${service}/api/cliente/${clientInfos.userId}/upload-foto?token=${token}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ foto: clientInfos.fotoUrl }),
      }
    );
    const { abort } = response;
    const data = await response.json();
    checkEmployeeToken(data, abort);
  } catch (error) {
    const isConnectionError = [
      ConnectionErrorNotFound,
      ConnectionErrorTimeOut,
      ConnectionErrorServerSide,
      ConnectionErrorFetchFaild,
      ApiErrorResponse,
    ].some((Error) => error instanceof Error);

    if (isConnectionError) throw error;
    throw new AppStorageError({
      message: "Não foi possivel salvar a foto do cliente no dispositivo",
      title: "Erro ao salvar foto",
      type: "error",
      method: "uploadClientePicture",
      error,
    });
  }
}

/**
 * @description Resposavel por salvar na memória interna do dispositivo os dados de cliente
 * @param {Object} clientInfo Dados necessarios para criação de um cliente,
 */
export async function createClient(clientInfo) {
  let cliente = {};
// Função Importante abaixo
  const routeDetails = await rotaService.mainRoute();
  const funcionarioLogado = await getUser();
  const openRoute = await rotaService.routeOpening();

  if (!openRoute) {
    throw new BusinessError({
      message: "Abra a rota para prosseguir com esta ação!.",
      title: "Falhar ao atualizar dados do cliente!.",
      type: "warning",
      method: "createClient",
    });
  }

  cliente.ativo = true;
  cliente.addedByApp = true;
  cliente.shouldSync = true;
  cliente.FuncionarioId = funcionarioLogado && funcionarioLogado.id;
  cliente.shouldUploadFoto = !!clientInfo.foto;
  cliente.EquipeId = funcionarioLogado.EquipeId;

  cliente.RotumId = routeDetails.id;
  cliente.id = window.DATABASE.generateUniqId();
  cliente.hash = String(window.DATABASE.generateUniqId());
  // cliente.idCliente = clientInfo.idCliente;

  // Address data
  cliente.bairroIdCache = clientInfo.bairro;
  cliente.cidade = clientInfo.cidade;
  cliente.estado = clientInfo.estado;
  cliente.logradouro = clientInfo.logradouro;
  cliente.numero = clientInfo.numero;
  cliente.pontoReferencia = clientInfo.pontoReferencia;
  cliente.observacao = clientInfo.observacao;

  // Personal data
  // cliente.foto = clientInfo.foto;
  cliente.nome = clientInfo.nome;
  cliente.cpf = clientInfo.cpf;
  cliente.cep = clientInfo.cep;
  cliente.email = clientInfo.email;
  cliente.sexo = clientInfo.sexo;
  cliente.conjugeCpf = clientInfo.cpfConjuge;
  cliente.assinatura = clientInfo.assinatura || "";
  cliente.saldoAtual = Moeda.create(0).mount();
  cliente.veaco = !!clientInfo.veaco;
  cliente.nomeLatinizado = latinize(clientInfo.nome);
  cliente.lowerCaseNome = cliente.nomeLatinizado.toLowerCase();
  cliente.nomeConjuge = clientInfo.nomeConjuge;
  cliente.nomeConjugeLatinizado = latinize(clientInfo.nomeConjuge);
  cliente.dataNascimento = moment(
    clientInfo.dataNascimento,
    "DD/MM/YYYY"
  ).toDate();
  // cliente.observacao = clientInfo.observacao;
  let client_documentos = [...clientInfo.documentos];
  let client_telefone = [...clientInfo.telefone];

  for (let key in cliente) {
    if (cliente[key] == "null") cliente[key] = null;
  }
  cliente.hash = String(window.DATABASE.generateUniqId());
  let newId = await models.Cliente.create(cliente);
  cliente["DATABASE_ID"] = newId;
  let clienteSaved = cliente;

  await models.ClienteBairro.create({
    ClienteId: clienteSaved.id,
    BairroId: cliente.bairroIdCache,
    shouldSync: true,
  });

  await models.ClienteFoto.put({
    shouldSync: true,
    foto: clientInfo.foto,
    ClienteId: clienteSaved.id,
    ClienteHash: clienteSaved.hash,
  });

  let documentosCliente = client_documentos.filter((d) => !!d);
  let documentosInsert = documentosCliente.map((doc) => {
    return {
      ClienteId: cliente.id,
      dataCriacao: moment().toDate(),
      ativo: true,
      foto: doc.foto,
      DocumentoId: doc.DocumentoId,
      hash: doc.hash ? doc.hash : window.DATABASE.generateUniqId(),
      hashRotaAbertura: openRoute.hash,
      shouldSync: true,
      shouldUploadFoto: doc.foto ? true : false,
    };
  });

  let proms = documentosInsert.map((d) => models.ClienteDocumento.create(d));
  await Promise.all(proms);

  let telefonesInsertingNotNull = client_telefone.filter((t) => !!t);
  let telefonesInsert = telefonesInsertingNotNull.map((tel) => {
    return {
      ClienteId: cliente.id,
      numero: tel,
      id: window.DATABASE.generateUniqId(),
      shouldSync: true,
      ativo: true,
    };
  });
  proms = telefonesInsert.map((t) => models.ClienteTelefone.create(t));
  await Promise.all(proms);

  return cliente.hash;
}
/**
 * @description Responsavel por atualizar dados de cliente
 */
export async function updateClient(dataClient) {
  // let { bairroIdCache, documentos, telefone, ...others } = dataClient;
  let routeOpening = await rotaService.routeOpening();

  if (!routeOpening) {
    throw new BusinessError({
      message: "Abra a rota para prosseguir com esta ação!.",
      title: "Falhar ao atualizar dados do cliente!.",
      type: "warning",
      method: "updateClient",
    });
  }

  dataClient.veaco = !!dataClient.veaco;
  dataClient.nomeLatinizado = latinize(dataClient.nome) || "";
  dataClient.lowerCaseNome = dataClient.nomeLatinizado.toLowerCase() || "";
  dataClient.nomeConjuge = dataClient.nomeConjuge || "";
  dataClient.nomeConjugeLatinizado = latinize(dataClient.nomeConjuge) || "";
  dataClient.dataNascimento = moment(
    dataClient.dataNascimento,
    "DD/MM/YYYY"
  ).toDate();

  // let clonedDataClient = {...dataClient};

  const [neighborhoodExist] = await models.ClienteBairro.getAll(
    "ClienteId",
    dataClient.id
  );
  if (!neighborhoodExist) {
    throw new BusinessError({
      message: "Bairro não encontrado!.",
      title: "Falhar ao salvar criar novo cliente.",
      type: "warning",
      method: "createClient",
    });
  }

  neighborhoodExist.BairroId = dataClient.bairro.id || dataClient.bairro;
  neighborhoodExist.shouldSync = true;

  const clientEdit = await mountClientEdit(dataClient);
  if (clientEdit) await models.ClienteEdit.put(clientEdit);

  await models.ClienteBairro.put(neighborhoodExist);

  const removedDocProms = dataClient.removedDocs.map((doc) => {
    doc.ativo = false;
    doc.shouldSync = true;
    if (doc.id)
      return models.ClienteDocumento.put({
        ativo: false,
        foto: doc.foto,
        ClienteId: dataClient.id,
        DocumentoId: doc.DocumentoId,
        DATABASE_ID: doc.DATABASE_ID,
        shouldUploadFoto: true,
        shouldSync: true,
        dataCriacao: new Date(),
        hashRotaAbertura: routeOpening.hash,
      });
    return null;
  });
  await Promise.all(removedDocProms);

  const newDocumentProms = dataClient.documentos.map((el) => {
    if (el.id) return null;

    return models.ClienteDocumento.create({
      ativo: true,
      foto: el.foto,
      ClienteId: dataClient.id,
      DocumentoId: el.DocumentoId,
      shouldUploadFoto: true,
      shouldSync: true,
      dataCriacao: new Date(),
      hashRotaAbertura: routeOpening.hash,
    });
  });
  await Promise.all(newDocumentProms);

  const removedTelProms = dataClient.removedTelephones.map((tel) => {
    if (!tel.id) return null;

    tel.ativo = false;
    return models.ClienteTelefone.put(tel);
  });
  await Promise.all(removedTelProms);

  const newTelephoneProms = dataClient.telefone.map((tel) => {
    if (tel.id) {
      return models.ClienteTelefone.put(tel);
    }

    if (typeof tel === "string") {
      return models.ClienteTelefone.create({
        numero: tel,
        ClienteId: dataClient.id,
        shouldSync: true,
        ativo: true,
      });
    }

    throw new Error("Houve um problema ao salvar o telefone do cliente.");
  });
  await Promise.all(newTelephoneProms);

  dataClient.bairroIdCache = dataClient.bairro.id || dataClient.bairro;
  dataClient.shouldSync = true;

  delete dataClient.bairro;
  delete dataClient.telefone;
  delete dataClient.documentos;
  delete dataClient.removedDocs;
  delete dataClient.removedTelephones;

  if (dataClient.shouldUploadFoto) {
    await saveClientPicture({ id: dataClient.id }, dataClient.foto);
  }
  return await models.Cliente.put(dataClient);
}

export async function getClientByHash(hash) {
  let client = await models.Cliente.getAll("hash", hash);
  client = client[0];

  let neighborhood = await models.ClienteBairro.getAll("ClienteId", client.id);
  if (neighborhood.length) {
    client.bairro = neighborhood[0].BairroId;
  }

  let clientDocsArray = await models.ClienteDocumento.getAll(
    "ClienteId",
    client.id
  );
  client.documentos = clientDocsArray.filter((a) => a.ativo);

  let clientTelsArray = await models.ClienteTelefone.getAll(
    "ClienteId",
    client.id
  );
  client.telefone = clientTelsArray.filter((a) => a.ativo);

  return client;
}

/**
 * @description Responsavel por recuperar a localização do usuario
 * @returns {Promise<{longitude: string, latitude: string}>} Coordenadas baseadas na localização dada pelo servicço web de geolocalização
 */

export function getClientLocation() {
  return new Promise(async (resolve) => {
    function fallback() {
      return new Promise((resolveFallback) => {
        setTimeout(() => {
          resolveFallback({
            latitude: null,
            longitude: null,
          });
        }, 1000);
      });
    }

    function geolocation() {
      return new Promise((resolveGeoLocation) => {
        const onError = (error) => {
          resolveGeoLocation({
            latitude: null,
            longitude: null,
          });
        };
        const onSuccess = (position) => {
          resolveGeoLocation({
            latitude: `${position.coords.latitude}`,
            longitude: `${position.coords.longitude}`,
          });
        };

        function requestGeolocationPermission() {
          return new Promise((resolvePermission) => {
            const permissionModal = document.createElement("div");
            permissionModal.style.position = "fixed";
            permissionModal.style.top = "0";
            permissionModal.style.left = "0";
            permissionModal.style.width = "100%";
            permissionModal.style.height = "100%";
            permissionModal.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
            permissionModal.style.display = "flex";
            permissionModal.style.alignItems = "center";
            permissionModal.style.justifyContent = "center";

            const modalContent = document.createElement("div");
            modalContent.style.backgroundColor = "#fff";
            modalContent.style.padding = "20px";
            modalContent.style.maxWidth = "400px";
            modalContent.style.textAlign = "center";

            const h2 = document.createElement("h2");
            h2.style.fontSize = "20px";
            h2.style.marginBottom = "10px";
            h2.textContent = "Permissão de Geolocalização";

            const p1 = document.createElement("p");
            p1.textContent =
              "Este aplicativo utiliza recursos de geolocalização para fornecer informações relevantes com base na sua localização.";

            const p2 = document.createElement("p");
            p2.textContent =
              "Por favor, permita o acesso à sua localização para uma experiência completa.";

            const allowButton = document.createElement("button");
            allowButton.style.backgroundColor = "#007bff";
            allowButton.style.color = "#fff";
            allowButton.style.border = "none";
            allowButton.style.padding = "10px 20px";
            allowButton.style.cursor = "pointer";
            allowButton.textContent = "Permitir";

            modalContent.appendChild(h2);
            modalContent.appendChild(p1);
            modalContent.appendChild(p2);
            modalContent.appendChild(allowButton);
            permissionModal.appendChild(modalContent);
            document.body.appendChild(permissionModal);

            allowButton.addEventListener("click", async () => {
              permissionModal.remove();
              resolvePermission(true);
            });

            permissionModal.addEventListener("click", (event) => {
              if (event.target === permissionModal) {
                permissionModal.remove();
                resolvePermission(false);
              }
            });
          });
        }

        async function checkGeolocationPermission() {
          const permissionResult = await requestGeolocationPermission();
          if (permissionResult) {
            navigator.geolocation.getCurrentPosition(onSuccess, onError);
          } else {
            onError();
          }
        }

        navigator.permissions.query({ name: "geolocation" }).then((result) => {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(onSuccess, onError);
          } else if (result.state === "prompt") {
            checkGeolocationPermission();
          } else if (result.state === "denied") {
            checkGeolocationPermission();
          } else {
            onError();
          }
        });
      });
    }

    try {
      const resultant = await Promise.race([geolocation(), fallback()]);
      resolve(resultant);
    } catch (err) {
      // Lidar com erros ou exibir uma mensagem ao usuário
      console.error("Erro ao obter a localização:", err.message);
      resolve({
        latitude: null,
        longitude: null,
      });
    }
  });
}

/**
 * @description Responsavel por identificar todos os dados que sofreram algum tipo de atualização e que estão diretamente relacionado
 * a algum cliente em especifico.
 * @returns {Promise} Quando resolvida retornará os dados do cliente e todas as models que sofrerão atualização
 */
export async function buildClientDataChanged(client) {
  try {
    client.oldId = client.id;
    const shouldSyncFilter = (client) => client.shouldSync;
    const clientKey = "ClienteId";

    let [
      receivement,
      schedules,
      schedulesToEdit,
      telephones,
      neighborhood,
      sales,
      clientEdit,
      exchanges,
      clientDocument,
      clientFoto,
    ] = await Promise.all([
      models.ClienteRecebimento.getAll(clientKey, client.id),
      models.ClienteAgendamento.getAll(clientKey, client.id),
      models.ClienteAgendamento.getAll(clientKey, client.id),
      models.ClienteTelefone.getAll(clientKey, client.id),
      models.Bairro.getClientNeighborhood(client.id),
      models.ClienteVenda.getAll(clientKey, client.id),
      models.ClienteEdit.getAll(clientKey, client.id),
      models.ClienteTrocaAvulso.getAll(clientKey, client.id),
      models.ClienteDocumento.getAll(clientKey, client.id),
      models.ClienteFoto.getAll(clientKey, client.id),
    ]);
    sales = sales.filter(shouldSyncFilter);
    exchanges = exchanges.filter(shouldSyncFilter);
    schedules = schedules.filter((el) => {
      return shouldSyncFilter(el) && el.id < 0;
    });

    clientEdit = clientEdit.filter(shouldSyncFilter);
    receivement = receivement.filter(shouldSyncFilter);
    clientDocument = clientDocument.filter(shouldSyncFilter);
    schedulesToEdit = schedulesToEdit.filter((el) => {
      return shouldSyncFilter(el) && el.editado && el.id > 0;
    });

    let salesArray = [];

    for (const sale of sales) {
      let saleData = sale;
      let [cestaVendidas, produtoVenda] = await Promise.all([
        models.CestaVenda.filter((a) => a.ClienteVendaId == sale.id),
        models.ProdutoVenda.filter((a) => a.ClienteVendaId == sale.id),
      ]);
      saleData.cestaVendidas = cestaVendidas;
      saleData.produtoVenda = produtoVenda;
      salesArray.push(saleData);
    }

    client.vendas = salesArray;
    client.clienteEdit = clientEdit;
    client.recebimentos = receivement;
    client.agendamentos = schedules;
    client.documentos = clientDocument;
    client.agendamentosEditados = schedulesToEdit;
    client.trocasAvulso = exchanges;
    client.telefones = telephones;
    client.bairro = neighborhood && neighborhood.id;
    client.foto = clientFoto[0];

    for (let fotoB64 of client.documentos) delete fotoB64.foto;

    return client;
  } catch (error) {
    throw new ClientError({
      clientName: client.nome,
      id: client.id,
      hash: client.hash,
      method: "buildClientDataChanged",
      DATABASE_ID: client.DATABASE_ID,
      error,
    });
  }
}

export function latinize(name) {
  if (!name) return;

  var map = {
    "-": " ",
    a: "á|à|ã|â|À|Á|Ã|Â",
    e: "é|è|ê|É|È|Ê",
    i: "í|ì|î|Í|Ì|Î",
    o: "ó|ò|ô|õ|Ó|Ò|Ô|Õ",
    u: "ú|ù|û|ü|Ú|Ù|Û|Ü",
    c: "ç|Ç",
    n: "ñ|Ñ",
  };

  // name = name.toLowerCase();

  for (var pattern in map) {
    name = name.replace(new RegExp(map[pattern], "g"), pattern);
  }

  return name;
}

export async function mountClientEdit(newClient) {
  const clientHash = newClient.hash;
  const oldClient = await models.Cliente.findByHash(clientHash);
  const { hash: hashRotaAbertura } = await rotaService.routeOpening();
  const user = await getUser();

  const propsToCompare = [
    "EquipeId",
    "FuncionarioId",
    "RotumId",
    "addedByApp",
    "hash",
    "IdCliente",
    "isChecked",
    "nomeLatinizado",
    "nomeConjugeLatinizado",
    "veaco",
    "nome",
    "cpf",
    "email",
    "nomeConjugue",
    "conjugeCpf",
    "sexo",
    "dataNascimento",
    "cep",
    "estado",
    "cidade",
    "bairro",
    "logradouro",
    "numero",
    "pontoReferencia",
    "dataNascimento",
    "email",
    "cep",
    "cpf",
    "nomeConjuge",
    "conjugeCpf",
    "sexo",
    "estado",
    "cidade",
    //"bairro",
    "observacao"
  ];

  const edit = propsToCompare.reduce((memo, prop) => {
    debugger
    if (prop == "dataNascimento") {
      if (moment(newClient[prop]).isValid()) {
        const isDifferent = (oldClient[prop] == null && newClient[prop] != null) || (oldClient[prop] != null && !moment(newClient[prop]).isSame(oldClient[prop]));
        if (isDifferent) {
          memo[prop] = {
            old: oldClient[prop],
            novo: newClient[prop],
          };
        }
      }

      return memo;
    }

    if (oldClient[prop] != newClient[prop]) {
      memo[prop] = {
        old: oldClient[prop],
        novo: newClient[prop],
      };
    }
    return memo;
  }, {});

  await Promise.all([
    calculateDocumentsDiff(edit, newClient, oldClient),
    calculateTelefoneDiff(edit, newClient, oldClient),
    calculateNeighborhoodDiff(edit, newClient, oldClient),
  ]);

  const hadChange = !!Object.keys(edit).length;

  return hadChange
    ? {
      ClienteId: newClient.id,
      FuncionarioId: user.id,
      RotumId: newClient.RotumId,
      origem: "PAP",
      hashRotaAbertura: hashRotaAbertura,
      dataRealizacao: new Date(),
      ativo: true,
      edit: edit,
      verificado: false,
      shouldSync: true,
    }
    : false;
}

async function calculateDocumentsDiff(edit, newClient, oldClient) {
  const oldClientDocumentos = await models.ClienteDocumento.getAll(
    "ClienteId",
    [oldClient.id]
  );
  const oldActiveDocuments = oldClientDocumentos.filter((doc) => doc.ativo);
  const isDifferentDocuments =
    newClient.documentos && !R.equals(oldActiveDocuments, newClient.documentos);

  if (isDifferentDocuments) {
    const getImportantInfo = (doc) => {
      return {
        ClienteId: doc.ClienteId,
        DocumentoId: doc.DocumentoId,
        foto: doc.foto,
        hash: doc.hash,
      };
    };
    edit.documentos = {
      old: JSON.stringify(oldActiveDocuments.map(getImportantInfo)),
      novo: JSON.stringify(newClient.documentos.map(getImportantInfo)),
    };
  }
  return edit;
}
async function calculateTelefoneDiff(edit, newClient, oldClient) {
  const oldClientTelefones = await models.ClienteTelefone.getAll("ClienteId", [
    oldClient.id,
  ]);
  const oldActiveTelefones = oldClientTelefones.filter((t) => t.ativo);
  const isDifferentTelefones =
    (newClient.telefone || newClient.removed) &&
    !R.equals(oldActiveTelefones, newClient.telefone);

  if (isDifferentTelefones) {
    const getNumber = (telefone) => telefone.numero || telefone;
    edit.telefones = {
      old: JSON.stringify(oldActiveTelefones.map(getNumber)),
      novo: JSON.stringify(newClient.telefone.map(getNumber)),
    };
  }
  return edit;
}

async function calculateNeighborhoodDiff(edit, newClient, oldClient) {
  debugger;
  const isDifferentNeighborhood =
    newClient.bairroIdCache &&
    oldClient.bairroIdCache &&
    newClient.bairroIdCache != oldClient.bairroIdCache;
  if (isDifferentNeighborhood) {
    const newNeighborhood = await models.Bairro.findById(
      newClient.bairroIdCache
    );
    const oldClientNeighborhood = await models.Bairro.findById(
      oldClient.bairroIdCache
    );
    edit.bairro = {
      old: oldClientNeighborhood.nome,
      novo: newNeighborhood.nome,
    };
  }

  return edit;
}

export async function toggleClientDefaulter(clientHash) {
  const [client] = await models.Cliente.getAll("hash", clientHash);
  if (!client) return;

  const routeOpening = await rotaService.routeOpening();
  const user = await getUser();

  const edit = {
    old: {
      veaco: client.veaco,
    },
    novo: {
      veaco: !client.veaco,
    },
  };

  const clientEdit = {
    edit: edit,
    ativo: true,
    origem: "PAP",
    shouldSync: true,
    addedByApp: true,
    ClienteId: client.id,
    FuncionarioId: user.id,
    dataRealizacao: new Date(),
    RotaId: routeOpening.RotaId,
    hashRotaAbertura: routeOpening.hash,
  };

  client.shouldSync = true;
  client.veaco = !client.veaco;

  await models.Cliente.put(client);
  await models.ClienteEdit.put(clientEdit);
}
