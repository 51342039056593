import { ClienteAgendamento, Cliente, Feriado } from "../../repository/models";
import LocalStorage from "../../utils/localStorage";
import RouteService from "../route";
import * as moment from "moment";
import BusinessError from "../../errorDefinition/BusinessError";
import { getClientLocation } from "../client";
import { getEnvVar } from "../../service/config";
import { getUser } from "../authentication/index";
import * as syncService from "../sync/activeSync/index";
import SyncNotificationCenter from "../notifications/syncNotificationCenter";

const routeService = new RouteService();
const localStorage = LocalStorage.instance;

const clienteAgendamento = new ClienteAgendamento();
const cliente = new Cliente();
const feriado = new Feriado();

/**
 * @description Cria agendamento para um cliente
 * @param {String} hash Identificador unico de cliente
 * @param {Object} data Dados do agendamento
 */
export async function createScheduling(hash, data) {
  let hashRotaAbertura = await localStorage.getItem("route-opening");
  if (!hashRotaAbertura) {
    throw new BusinessError({
      type: "warning",
      method: "createScheduling",
      title: "Falha ao criar novo agendamento!",
      message: "Abra a rota para continuar com esta ação.",
    });
  }

  const client = await cliente.findByHash(hash);
  let existScheduling = await clienteAgendamento.getAll("ClienteId", client.id);

  existScheduling = existScheduling.filter(
    (a) => a.tipo.toLowerCase() == data.tipo.toLowerCase() && a.ativo
  );

  let isScheduleValid = await validateSchedule(data);

  if (isScheduleValid.error) {
    throw new BusinessError({
      message: isScheduleValid.message,
      type: "error",
      title: "Não foi possível criar este agendamento!",
      method: "createScheduling",
    });
  }

  await Promise.all(
    existScheduling.map(async (schedule) => {
      await removeScheduleSameType(hash, schedule.tipo);
    })
  );

  const coord = await getClientLocation();

  // Pega Funcionario responsável pelo agendamento
  const funcionarioLogado = await getUser();
  data.FuncionarioId = funcionarioLogado && funcionarioLogado.id;
  // Fim da Parte do Cliente

  data.ClienteId = client.id;// colocar aqui
  
  data.dataCriacao = new Date();
  data.ativo = true;
  data.shouldSync = true;
  data.hashRotaAbertura = hashRotaAbertura.hash;
  data.longitude = coord.longitude;
  data.latitude = coord.latitude;
  const DATABASE_ID = await clienteAgendamento.create(data);

  client.shouldSync = true;
  await cliente.put(client);

  return {
    clientId: client.id,
    clienteHash: client.hash,
    ScheduleDatabaseId: DATABASE_ID,
    desactivateSchedules: existScheduling.map((schedule) => ({
      hash: schedule.hash,
      id: schedule.id,
      DATABASE_ID: schedule.DATABASE_ID,
    })),
  };
}

export async function validateSchedule(data) {
  //AGENDAMENTO NO DOMINGO

  if (moment(data.dataRecebimento).isoWeekday() == 7) {
    return {
      error: true,
      message: "Data do agendamento caindo no domingo!",
    };
  }

  let feriados = await feriado.getAll();
  //AGENDAMENTO EM FERIADOS
  let holiday;
  let inFeriado = feriados.some((feriado) => {
    const isSame = moment(feriado.data, "DD/MM").isSame(
      moment(data.dataRecebimento).startOf("day")
    );
    if (isSame) holiday = feriado;
    return isSame;
  });

  if (inFeriado) {
    return {
      error: true,
      message: `Data do agendamento caindo em um feriado! (${holiday.nome} - ${holiday.data})`,
    };
  }
  //Verificação da variavel de ambiente que controla o limite de meses
  let maxMonthAgendamentos = await getEnvVar("MAX_MES_AGENDAMENTO");
  if (!maxMonthAgendamentos) maxMonthAgendamentos = 0;
  maxMonthAgendamentos = Math.abs(parseInt(maxMonthAgendamentos));
  if (!maxMonthAgendamentos || maxMonthAgendamentos == 0) {
    return { error: false };
  }

  let limit = moment().add(maxMonthAgendamentos, "month");
  let isAfterLimit = moment(data.dataRecebimento).isAfter(limit, "day");
  if (isAfterLimit) {
    return {
      error: true,
      message:
        "Data do agendamento está além do limite permitido! Isso pode ser configirado pelo administrador da empresa!",
    };
  }

  return { error: false };
}

export async function removeScheduleFromDaily(clientHash, scheduleType) {
  const client = await cliente.findByHash(clientHash);

  const schedules = await clienteAgendamento.getAllClientScheduling(clientHash);
  const routeOpening = await routeService.routeOpening();
  // const startDate = moment(routeOpening.listaDiariaInicio, "DD/MM/YYYY");
  const endDate = moment(routeOpening.listaDiariaFinal, "DD/MM/YYYY").endOf(
    "day"
  );

  scheduleType = scheduleType.toLowerCase();
  const schedulesToRemove = schedules.filter(
    (schedule) =>
      schedule.tipo.toLowerCase() == scheduleType &&
      moment(schedule.dataRecebimento).isSameOrBefore(endDate)
  );

  client.shouldSync = true;
  await cliente.put(client);
  await Promise.all(
    schedulesToRemove.map((schedule) => {
      schedule.ativo = false;
      schedule.editado = true;
      schedule.shouldSync = true;
      return clienteAgendamento.put(schedule);
    })
  );
}

export async function removeScheduleSameType(clientHash, scheduleType) {
  const client = await cliente.findByHash(clientHash);

  const schedules = await clienteAgendamento.getAllClientScheduling(clientHash);

  scheduleType = scheduleType.toLowerCase();
  const schedulesToRemove = schedules.filter(
    (schedule) => schedule.tipo.toLowerCase() == scheduleType
  );

  client.shouldSync = true;
  await cliente.put(client);
  await Promise.all(
    schedulesToRemove.map((schedule) => {
      schedule.ativo = false;
      schedule.editado = true;
      schedule.shouldSync = true;
      return clienteAgendamento.put(schedule);
    })
  );
}

/**
 *
 * @description Edita um agendamento
 * @param {String} clientHash Identificador unico de cliente
 * @param {String} schedulingHash Identificador unico do agendamento
 * @param {*} data Dados que irão sobreescrever os antigos dados do agendamento
 */
export async function editScheduling(clientHash, schedulingHash, data) {
  const client = await cliente.findByHash(clientHash);

  const { error, message: errorMessage } = await validateSchedule(data);
  if (error) {
    throw new BusinessError({
      message: errorMessage,
      type: "error",
      title: "Não foi possível criar este agendamento!",
      method: "editScheduling",
    });
  }

  let existScheduling = await clienteAgendamento.getAll("hash", schedulingHash);
  existScheduling = existScheduling.find((a) => a.ativo);
  if (!existScheduling) throw new Error("Agendamento não encontrado");

  const schedulingUpdated = Object.assign(existScheduling, data);

  schedulingUpdated.editado = true;
  schedulingUpdated.updatedDate = new Date();
  schedulingUpdated.shouldSync = true;
  client.shouldSync = true;

  await cliente.put(client);
  await clienteAgendamento.put(schedulingUpdated);
}

export async function jumpClient(clientHash) {
  const schedules = await clienteAgendamento.getAllClientScheduling(clientHash);
  const routeOpening = await routeService.routeOpening();
  // const startDate = moment(routeOpening.listaDiariaInicio, "DD/MM/YYYY").startOf('day');
  const endDate = moment(routeOpening.listaDiariaFinal, "DD/MM/YYYY").endOf(
    "day"
  );

  const reschedule = schedules.filter((schedule) => {
    return moment(schedule.dataRecebimento).isSameOrBefore(endDate);
  });
  let clonedSchedule;
  try {
    await Promise.all(
      reschedule.map(async (schedule) => {
        schedule.ativo = false;
        await editScheduling(clientHash, schedule.hash, schedule);
      })
    );
    await Promise.all(
      reschedule.map(async (schedule) => {
        clonedSchedule = (window.structuredClone &&
          window.structuredClone({ ...schedule })) || { ...schedule };

        delete clonedSchedule.id;
        delete clonedSchedule.hash;
        delete clonedSchedule.DATABASE_ID;
        delete clonedSchedule.ClienteRecebimentoId;
        delete clonedSchedule.ClienteVendaId;

        clonedSchedule.ativo = true;
        clonedSchedule.dataRecebimento = endDate
          .startOf("day")
          .add(3, "hours")
          .add(1, "day")
          .toDate();
        clonedSchedule.editado = true;
        await createScheduling(clientHash, clonedSchedule);
        // await removeScheduleFromDaily(clientHash, clonedSchedule.tipo);
      })
    );

    // Check if user is working online, and if so, send expenses.
    let localStorageInstance = LocalStorage.instance;
    let localConfig = await localStorageInstance.getItem("local-config");

    if (
      localConfig &&
      localConfig.OnlineOffline &&
      reschedule[0] &&
      reschedule[0].ClienteId
    ) {
      await syncService.syncOneClient((eventName, data) => {
        SyncNotificationCenter.instance.notifyAll("single-client-sync", data);
      }, reschedule[0].ClienteId);
    }

    return null;
  } catch (e) {
    console.log(e);

    return clonedSchedule?.tipo;
  }
}

export async function getSchedulesByTypeAndClient(clientHash, type) {
  if (!type || !clientHash)
    throw new Error(
      "You must to define hash and type. Error on getSchedulesByTypeAndClient"
    );
  type = type.toLocaleLowerCase();
  const schedules = await clienteAgendamento.getAllClientScheduling(clientHash);
  const getByType = (schedule) => schedule.tipo.toLocaleLowerCase() === type;
  const filteredSchedules = schedules.filter(getByType);

  filteredSchedules.delete = function () {
    const setRemoved = {
      ativo: false,
      editado: true,
      updatedDate: new Date(),
      shouldSync: true,
    };
    const proms = this.map((schedule) => {
      const removedSchedule = Object.assign(schedule, setRemoved);
      return clienteAgendamento.put(removedSchedule);
    });
    return Promise.all(proms);
  };

  return filteredSchedules;
}
